
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-widget-7",
  components: {},
  props: {
    widgetClasses: String
  },
  setup() {
    const items = [
      [
        {
          icon: {
            file: "media/icons/duotone/Media/Playlist1.svg",
            color: "success"
          },
          info: {
            title: "Media Device",
            description: "Voice and video recorder"
          },
          date: "5 day ago",
          badge: {
            label: "Delivered",
            color: "success"
          }
        },
        {
          icon: {
            file: "media/icons/duotone/Layout/Layout-4-blocks-2.svg",
            color: "danger"
          },
          info: {
            title: "Special Meal",
            description: "Quona Rice"
          },
          date: "2 day ago",
          badge: {
            label: "Delivered",
            color: "danger"
          }
        },
        {
          icon: {
            file: "media/icons/duotone/Home/Globe.svg",
            color: "primary"
          },
          info: {
            title: "New Users",
            description: "Awesome Users"
          },
          date: "4 day ago",
          badge: {
            label: "Delivered",
            color: "primary"
          }
        },
        {
          icon: {
            file: "media/icons/duotone/Home/Library.svg",
            color: "warning"
          },
          info: {
            title: "Active Customers",
            description: "Best Customers"
          },
          date: "1 day ago",
          badge: {
            label: "Delivered",
            color: "warning"
          }
        },
        {
          icon: {
            file: "media/icons/duotone/Design/Color-profile.svg",
            color: "info"
          },
          info: {
            title: "Strawberry Boxes",
            description: "From Spain"
          },
          date: "7 day ago",
          badge: {
            label: "Delivered",
            color: "info"
          }
        }
      ],

      [
        {
          icon: {
            file: "media/icons/duotone/Shopping/Box2.svg",
            color: "info"
          },
          info: {
            title: "Strawberry Boxes",
            description: "From Spain"
          },
          date: "4 week ago",
          badge: {
            label: "Delivered",
            color: "info"
          }
        },
        {
          icon: {
            file: "media/icons/duotone/Layout/Layout-4-blocks-2.svg",
            color: "danger"
          },
          info: {
            title: "Special Meal",
            description: "Quona Rice"
          },
          date: "2 week ago",
          badge: {
            label: "Delivered",
            color: "danger"
          }
        },
        {
          icon: {
            file: "media/icons/duotone/Home/Library.svg",
            color: "warning"
          },
          info: {
            title: "Active Customers",
            description: "Best Customers"
          },
          date: "5 week ago",
          badge: {
            label: "Delivered",
            color: "warning"
          }
        }
      ],

      [
        {
          icon: {
            file: "media/icons/duotone/Shopping/Cart3.svg",
            color: "success"
          },
          info: {
            title: "Media Device",
            description: "Voice and video recorder"
          },
          date: "3 month ago",
          badge: {
            label: "Delivered",
            color: "success"
          }
        },
        {
          icon: {
            file: "media/icons/duotone/Layout/Layout-4-blocks-2.svg",
            color: "danger"
          },
          info: {
            title: "Special Meal",
            description: "Quona Rice"
          },
          date: "5 month ago",
          badge: {
            label: "Delivered",
            color: "danger"
          }
        },
        {
          icon: {
            file: "media/icons/duotone/Home/Library.svg",
            color: "warning"
          },
          info: {
            title: "Active Customers",
            description: "Best Customers"
          },
          date: "6 month ago",
          badge: {
            label: "Delivered",
            color: "warning"
          }
        },
        {
          icon: {
            file: "media/icons/duotone/Shopping/Box2.svg",
            color: "info"
          },
          info: {
            title: "Strawberry Boxes",
            description: "From Spain"
          },
          date: "4 month ago",
          badge: {
            label: "Delivered",
            color: "info"
          }
        }
      ]
    ];

    return {
      items
    };
  }
});
